<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" md="3">
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          :return-value.sync="date"
          transition="scale-transition"
          offset-y
          max-width="290px"
          min-width="auto"
          :close-on-click="false"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="date"
              label="Mes a consultar"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            color="primary"
            type="month"
            no-title
            scrollable
          >
            <v-spacer></v-spacer>
            <v-btn text @click="menu = false"> Cancel </v-btn>
            <v-btn text @click="$refs.menu.save(date)"> OK </v-btn>
          </v-date-picker>
        </v-menu>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-5"
            color="indigo"
            dark
            :class="hover ? 'darken-3' : 'darken-2'"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 font-weight-black">
                  {{ computedFacturado | toCurrency }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Facturado </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-5"
            color="indigo"
            dark
            :class="hover ? 'darken-3' : 'darken-2'"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 font-weight-black">
                  {{ computedNeto | toCurrency }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Backorder </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
      <v-col cols="12" md="4">
        <v-hover v-slot:default="{ hover }">
          <v-card
            class="pa-5"
            color="indigo"
            dark
            :class="hover ? 'darken-3' : 'darken-2'"
          >
            <v-row no-gutters>
              <v-col cols="12" class="text-center">
                <span class="display-1 font-weight-black">
                  {{ computedPorFacturar | toCurrency }}
                </span>
              </v-col>
              <v-col cols="12" class="text-center">
                <span class="title font-weight-light"> Total </span>
              </v-col>
            </v-row>
          </v-card>
        </v-hover>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-progress-linear :value="computedPorcentaje" height="35" color="info">
          <template v-slot:default="{ value }">
            <strong>{{ Math.ceil(value) }}%</strong>
          </template>
        </v-progress-linear>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="12">
        <v-card id="ventas-producto">
          <v-toolbar flat>
            <v-toolbar-title> Backorder por cliente </v-toolbar-title>
            <v-spacer />
          </v-toolbar>
          <v-divider class="mb-3 primary" />
          <v-card-text class="pa-3" style="height: auto">
            <v-chart
              ref="chartPorcentajeVentas"
              theme="walden"
              class="ma-auto"
              style="width: 100%"
              :options="chartVentaMensual"
              autoresize
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { TweenMax } from "gsap";
import ECharts from "vue-echarts";
// import ECharts modules manually to reduce bundle size
import "echarts/lib/chart/line";
import "echarts/lib/chart/bar";
import "echarts/lib/chart/pie";
import "echarts/lib/component/tooltip";
import "echarts/lib/component/toolbox";
import "echarts/lib/component/legend";
import theme from "@/assets/branding/chartTheme.json";

ECharts.registerTheme("walden", theme);

export default {
  name: "Mensual",
  components: {
    "v-chart": ECharts,
  },
  data: (vm) => ({
    date: new Date().toISOString().substr(0, 7),
    menu: false,
    porcentaje: 0,
    tweenPorcentaje: 0,
    facturado: 0,
    tweenPorFacturar: 0,
    porFacturar: 0,
    tweenPorFaturar: 0,
    neto: 0,
    tweenNeto: 0,
    chartVentaMensual: {
      tooltip: {
        confine: true,
        trigger: "axis",
        axisPointer: {
          // Use axis to trigger tooltip
          type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
        },
        formatter: function (params) {
          let output = "<b>" + params[0].name + "</b><br/>";
          for (let i = 0; i < params.length; i++) {
            output +=
              params[i].marker +
              params[i].seriesName +
              ": " +
              vm.$options.filters.toCurrency(params[i].value); // : every 2nth

            if (i !== params.length - 1) {
              // Append a <br/> tag if not last in loop
              output += "<br/>";
            }
          }
          return output;
        },
      },
      legend: {
        data: ["Facturado", "Por Facturar"],
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        containLabel: true,
      },
      xAxis: {
        type: "value",
        axisLabel: {
          formatter: "${value}",
        },
      },
      yAxis: {
        type: "category",
        position: "right",
        data: [],
      },
      series: [],
    },
  }),
  computed: {
    computedFacturado() {
      return this.tweenPorFacturar;
    },
    computedPorFacturar() {
      return this.tweenPorFaturar;
    },
    computedNeto() {
      return this.tweenNeto;
    },
    computedPorcentaje() {
      return this.tweenPorcentaje + 0;
    },
  },
  watch: {
    date() {
      this.getBackOrder();
    },
    facturado(newValue) {
      TweenMax.to(this.$data, 0.7, { tweenPorFacturar: newValue });
    },
    porFacturar(newValue) {
      TweenMax.to(this.$data, 0.7, { tweenPorFaturar: newValue });
    },
    neto(newValue) {
      TweenMax.to(this.$data, 0.7, { tweenNeto: newValue });
    },
    porcentaje(newValue) {
      TweenMax.to(this.$data, 0.7, { tweenPorcentaje: newValue });
    },
  },
  mounted() {
    this.getBackOrder();
    window.addEventListener("resize", this.onResize, { passive: true });
  },
  beforeDestroy() {
    if (typeof window === "undefined") return;
    window.removeEventListener("resize", this.onResize);
  },
  methods: {
    getBackOrder() {
      this.$refs.chartPorcentajeVentas.chart.showLoading("default", {
        text: "Cargando Datos",
      });
      console.log(this.date);
      this.$http
        .post("invoice/getBackOrder", {
          date: this.date,
          clients: this.selectedClients,
        })
        .then((res) => {
          let reporte = res.data.report;
          this.facturado = reporte.reduce(
            (acc, current) => acc + current.total,
            0
          );
          this.neto = reporte.reduce(
            (acc, current) => acc + parseFloat(current.backorder),
            0
          );
          this.porFacturar = Math.max(0, this.neto + this.facturado);
          if (this.neto <= this.porFacturar) {
            this.porcentaje = Math.max(
              0,
              (this.facturado / this.porFacturar) * 100
            );
          } else if (this.neto >= this.porFacturar) {
            this.porcentaje = 100;
          } else {
            this.porcentaje = 0;
          }

          let yAxis = reporte.map((a) => a.name);
          let facturado = reporte.map((a) => a.total);
          let backorder = reporte.map((a) => Math.max(0, a.backorder));
          let me = this;

          this.$refs.chartPorcentajeVentas.chart.setOption(
            {
              grid: {
                left: "3%",
                right: "4%",
                bottom: "3%",
                containLabel: true,
              },
              tooltip: {
                confine: true,
                trigger: "axis",
                axisPointer: {
                  // Use axis to trigger tooltip
                  type: "shadow", // 'shadow' as default; can also be 'line' or 'shadow'
                },
                formatter: function (params) {
                  let output = "<b>" + params[0].name + "</b><br/>";
                  let total = params.reduce((tot, b) => tot + b.value, 0);
                  for (let i = 0; i < params.length; i++) {
                    output +=
                      params[i].marker +
                      params[i].seriesName +
                      ": " +
                      me.$options.filters.toCurrency(params[i].value); // : every 2nth

                    if (i !== params.length - 1) {
                      // Append a <br/> tag if not last in loop
                      output += "<br/>";
                    }
                  }
                  output +=
                    "<br/><br/>Total: " + me.$options.filters.toCurrency(total);
                  return output;
                },
              },
              legend: {
                data: ["Facturado", "Por facturar"],
              },
              xAxis: {
                type: "value",
                axisLabel: {
                  formatter: "${value}",
                },
              },
              yAxis: {
                type: "category",
                position: "right",
                data: yAxis,
              },
              series: [
                {
                  name: "Facturado",
                  type: "bar",
                  // barGap: "-100%",
                  stack: "total",
                  showBackground: true,
                  emphasis: {
                    focus: "series",
                  },
                  data: facturado,
                },
                {
                  name: "Por facturar",
                  type: "bar",

                  stack: "total",
                  showBackground: true,
                  emphasis: {
                    focus: "series",
                  },
                  data: backorder,
                },
              ],
            },
            true
          );
          this.onResize();
        })
        .catch((error) => {
          if (error.response) {
            if (error.response.data.msg) {
              this.$toast.error(error.response.data.msg);
            } else {
              this.$toast.error("Ocurrio un error inesperado.");
            }
          } else {
            this.$toast.error("Ocurrio un error inesperado.");
          }
        })
        .finally(() => {
          this.$refs.chartPorcentajeVentas.chart.hideLoading();
          // this.clientsLoading = false;
          // this.$store.dispatch("working", false);
        });
    },
    onResize() {
      if (this.$vuetify.breakpoint.mdAndUp) {
        this.$refs.chartPorcentajeVentas.chart.setOption({
          grid: {
            left: "3%",
            right: "4%",
            bottom: "3%",
            containLabel: true,
          },
          yAxis: {
            label: {
              show: true,
            },
          },
        });
      } else if (this.$vuetify.breakpoint.smAndDown) {
        this.$refs.chartPorcentajeVentas.chart.setOption({
          grid: {
            left: "0%",
            right: "0%",
            bottom: "0%",
            containLabel: false,
          },
          yAxis: {
            label: {
              show: false,
            },
          },
        });
      }
    },
  },
};
</script>

<style scoped></style>
