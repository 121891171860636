var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"return-value":_vm.date,"transition":"scale-transition","offset-y":"","max-width":"290px","min-width":"auto","close-on-click":false},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"label":"Mes a consultar","prepend-icon":"mdi-calendar","readonly":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"color":"primary","type":"month","no-title":"","scrollable":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.menu = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){return _vm.$refs.menu.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5",class:hover ? 'darken-3' : 'darken-2',attrs:{"color":"indigo","dark":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.computedFacturado))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-light"},[_vm._v(" Facturado ")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5",class:hover ? 'darken-3' : 'darken-2',attrs:{"color":"indigo","dark":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.computedNeto))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-light"},[_vm._v(" Backorder ")])])],1)],1)]}}])})],1),_c('v-col',{attrs:{"cols":"12","md":"4"}},[_c('v-hover',{scopedSlots:_vm._u([{key:"default",fn:function(ref){
var hover = ref.hover;
return [_c('v-card',{staticClass:"pa-5",class:hover ? 'darken-3' : 'darken-2',attrs:{"color":"indigo","dark":""}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"display-1 font-weight-black"},[_vm._v(" "+_vm._s(_vm._f("toCurrency")(_vm.computedPorFacturar))+" ")])]),_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[_c('span',{staticClass:"title font-weight-light"},[_vm._v(" Total ")])])],1)],1)]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-progress-linear',{attrs:{"value":_vm.computedPorcentaje,"height":"35","color":"info"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var value = ref.value;
return [_c('strong',[_vm._v(_vm._s(Math.ceil(value))+"%")])]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","md":"12"}},[_c('v-card',{attrs:{"id":"ventas-producto"}},[_c('v-toolbar',{attrs:{"flat":""}},[_c('v-toolbar-title',[_vm._v(" Backorder por cliente ")]),_c('v-spacer')],1),_c('v-divider',{staticClass:"mb-3 primary"}),_c('v-card-text',{staticClass:"pa-3",staticStyle:{"height":"auto"}},[_c('v-chart',{ref:"chartPorcentajeVentas",staticClass:"ma-auto",staticStyle:{"width":"100%"},attrs:{"theme":"walden","options":_vm.chartVentaMensual,"autoresize":""}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }